import {
  StyledMultipleTestimonialBlockQuote,
  StyledQuoteBlock,
  StyledSingleTestimonialBlockQuote,
  StyledSlider,
  StyledSliderArrows,
  StyledSliderPagination,
  StyledSliderTopWrapper
} from "@components/TestimonialBlockQuote/TestimonialBlockQuote.styles";
import { GridContainer } from '@theme/lib';
import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { SwiperProps, SwiperSlideProps } from "swiper/react";

import { TestimonialsFragment } from '@/__generated__/graphql';
import { getLanguage } from "@/lib/config";
import { SwiperModule } from "swiper/types";

type TestimonialsFragment_testimonials = Exclude<TestimonialsFragment['testimonials'][0], undefined | null>

function QuoteBlock(data: TestimonialsFragment_testimonials) {
  const language = getLanguage();
  return (
    <StyledQuoteBlock>
      <div className="quote__wrapper">
        <figure>
          <blockquote lang={language !== 'en' ? language : undefined}>{data.quote}</blockquote>
          {data.citation && (
            <figcaption lang={language !== 'en' ? language : undefined}>- {data.citation}</figcaption>
          )}
        </figure>
      </div>
    </StyledQuoteBlock>
  )
}

// Todo: when we have time lets move slider as re-usable component in future.
function Slider(data: TestimonialsFragment): ReactElement {
  const language = getLanguage();
  // Dynamically load swiper.
  const [swiperModules, setSwiperModules] = useState<SwiperModule[]>([]);
  const [Swiper, setSwiper] = useState<FunctionComponent<SwiperProps> | null>(null);
  const [SwiperSlide, setSwiperSlide] = useState<FunctionComponent<SwiperSlideProps> | null>(null);

  useEffect(() => {
    const getSwiperModules = async () => {
      const modules = await import('swiper');
      const { Navigation, Pagination } = await import('swiper/modules');
      const swiperComponents = await import('swiper/react');

      setSwiperModules([Pagination, Navigation]);
      setSwiper(swiperComponents.Swiper);
      setSwiperSlide(swiperComponents.SwiperSlide);
    };

    getSwiperModules();
  }, []);
  // Dynamically load swiper - End.

  if (!data?.testimonials?.length || data?.testimonials?.length < 1) {
    return <></>;
  }
  return (
    <div style={{ overflow: 'hidden' }}>
      <GridContainer>
        <StyledSlider>
          <StyledSliderTopWrapper>
            <h2 lang={language !== 'en' ? language : undefined}>{data.title}</h2>
            <StyledSliderArrows>
              <button className={`swiper__prev swiper__prev-${data.id}`}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <circle r="24" transform="matrix(-1 0 0 1 24 24)" fill="#008945"/>
                    <path d="M33.8823 22.5H35.3823V25.5H33.8823V22.5ZM12.4307 25.0607C11.8449 24.4749 11.8449 23.5251 12.4307 22.9393L21.9767 13.3934C22.5625 12.8076 23.5122 12.8076 24.098 13.3934C24.6838 13.9792 24.6838 14.9289 24.098 15.5147L15.6127 24L24.098 32.4853C24.6838 33.0711 24.6838 34.0208 24.098 34.6066C23.5122 35.1924 22.5625 35.1924 21.9767 34.6066L12.4307 25.0607ZM33.8823 25.5L13.4914 25.5V22.5L33.8823 22.5V25.5Z" fill="#C7F700"/>
                  </g>
                </svg>
              </button>
              <button className={`swiper__next swiper__next-${data.id}`}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#008945"/>
                  <path d="M14.1177 22.5H12.6177V25.5H14.1177V22.5ZM35.5693 25.0607C36.1551 24.4749 36.1551 23.5251 35.5693 22.9393L26.0233 13.3934C25.4375 12.8076 24.4878 12.8076 23.902 13.3934C23.3162 13.9792 23.3162 14.9289 23.902 15.5147L32.3873 24L23.902 32.4853C23.3162 33.0711 23.3162 34.0208 23.902 34.6066C24.4878 35.1924 25.4375 35.1924 26.0233 34.6066L35.5693 25.0607ZM14.1177 25.5L34.5086 25.5V22.5L14.1177 22.5V25.5Z" fill="#C7F700"/>
                </svg>
              </button>
            </StyledSliderArrows>
          </StyledSliderTopWrapper>

          {swiperModules.length > 0 && Swiper && SwiperSlide && <Swiper
            navigation={{ nextEl: `.swiper__next-${data.id}`, prevEl: `.swiper__prev-${data.id}` }}
            spaceBetween={16}
            slidesPerView="auto"
            pagination={{ clickable: true, el: `.swiper__pagination-${data.id}` }}
            modules={swiperModules}
            breakpoints={{
              1250: {
                spaceBetween: 32,
              },
            }}
            wrapperTag="ul"
          >
            {data.testimonials.map((quote, id) => (
              <SwiperSlide key={id} tag="li">
                <QuoteBlock {...quote} />
              </SwiperSlide>
            ))}
          </Swiper> }

          <StyledSliderPagination className={`swiper__pagination swiper__pagination-${data.id}`} />
        </StyledSlider>
      </GridContainer>
    </div>
  );
}


export default function TestimonialBlockQuote(data: TestimonialsFragment): ReactElement {
  const [isSingleQuote, setIsSingleQuote] = useState(() => data?.testimonials?.length === 1);
  const language = getLanguage();
  return (
    <div>
      {isSingleQuote ?
        (
          <StyledSingleTestimonialBlockQuote>
            <div className="grid__container">
              <div className="grid__wrapper">
                <h2 lang={language !== 'en' ? language : undefined}>{data.title}</h2>
                <QuoteBlock {...data.testimonials[0]}/>
              </div>
            </div>
          </StyledSingleTestimonialBlockQuote>
        ) :
        (
          <StyledMultipleTestimonialBlockQuote>
            <Slider {...data} />
          </StyledMultipleTestimonialBlockQuote>
        )}
    </div>
  )
}
